#codeSnippetOutput{
    flex-grow: 1;
    // background-color: #363636;
    width: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #656262;
    .header{
        font-size: 18px;
        font-weight: 600;
        margin: 5px;
    }
    .response-detail{
        margin: 10px 15px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #FFFFFF;

        .response-detail-label{
            font-size: 16px;
            font-weight: 500;
        }
        p{
            margin: 0px 20px;
            padding: 0;
            white-space: pre-wrap;
        }
    }
    .passed-test-case{
        color: #1bc01b;
        font-size: 16px;
            margin-left: 5px;
    }
    .failed-test-case{
        color: #e32b2b;
        font-size: 16px;
            margin-left: 5px;
    }
}