@import "../styles/scss/variables.scss";

.AppHeader {
    height: $header-height;
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px; //
    flex-shrink: 0;
    .logo {
        width: 90px;
    }
    .profile-wrpr{
        display: flex;
        flex-direction: column;
        text-align: right;
        color: #212121;
        .username {

        }
        .logout {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;
        }  
    }
    &.dark {
        background: #2C2C2C;
        .profile-wrpr {
            color: #FFFFFF;
        }
    }
}