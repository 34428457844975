.candidateTable{
    margin-top: 10px;
    overflow: scroll;
    
    .table{
        width: 100%;
        margin-bottom: 1rem;
        //border: 1px solid #dee2e6;
        text-indent: initial;
        border-spacing: 2px;
        border-collapse: collapse;

        thead th, tbody td{
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
            padding: 0.75rem;
            //border: 1px solid #dee2e6;
        }

        tr{
            text-align: left;
        }

        thead tr{
            background: #FFFFFF;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
        }

        tbody tr{
            cursor: pointer;
        }

        .review_status, .evaulated-status{
            span{
                border-radius: 20px;
                font-weight: 500;
                padding: 5px;   
            }
            .PENDING, .STARTED{
                background-color: #ecc983;
                border: 1px solid #ddb25d;
            }
            .APPROVED, .EVALUATED{
                background-color: #3c8642;
                border: 1px solid #2c6c31
            }
            .REJECTED{
                background-color: #d23e3e;
                border: 1px solid #a72020;
            }

        }
    }
}
