@import "../styles/scss/variables.scss";

$panel-width: 50%;

.MyDetailsPage {
    //height: 100%;
    @include calculated-height;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    .label {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #094A9E;
        margin: 15px 0;
    }
    .ui.form{
        font-size: 1rem;
        width: $panel-width;
        background: #FFF;
        padding: 30px;
        box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    }

    .ui.form .row{
        display: flex;
        flex-wrap: wrap;
    }

    .ui.form .field{
        clear: both;
        width: 60%;
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;

        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        position: relative;
    }

    .ui.form .field > label{
        margin: 0 0 0.28571429rem 0;
        color: rgba(0,0,0,.87);
        font-size: .92857143em;
        font-weight: 700;
    }

    .ui.form .field > p{
        margin: 1em 0;
        color: #ec3e3e;
        margin: 2px 0 5px 0;
    }

    .ui.form input[type=text], .ui.form input[type=number], .ui.form input[type=email], .ui.form input[type=file]{
        width: 100%;
        font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
        margin: 0;
        outline: 0;
        -webkit-appearance: none;
        line-height: 1.21428571em;
        padding: 0.67857143em 1em;
        font-size: 1em;
        background: #fff;
        border: 1px solid rgba(34,36,38,.15);
        color: rgba(0,0,0,.87);
        border-radius: 0.28571429rem;
        box-shadow: 0 0 0 0 transparent inset;
        transition: color .1s ease,border-color .1s ease;
    }

    .upload-status{
        font-weight: normal !important;
        font-size: .92857143em;
        line-height: 16px;
        letter-spacing: -0.02em;
        padding-top: 5px;
        color: green !important;
        display: inherit;
    }

    .file-size-info{
        font-weight: normal !important;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #616161 !important;
        padding-top: 5px;
    }
    .form-actions{
        display: flex;
        justify-content: flex-end;
        align-content: center;
        width: $panel-width;
        flex-wrap: wrap;

        input[type="submit"],[type="button"]{
            position: relative;
            background-color: #01579B;
            border: #01579B;
            border-radius: 5px;
            padding: 12.5px 35px;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            cursor: pointer;
            margin-top: 20px;
            &:disabled{
                background-color: #a6a2a2;
            }
        }
        input:first-child{
            margin-right: 10px;
        }
    }

}