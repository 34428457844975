.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;
    z-index: 999;
    .loader{
        left: 50%;
        top: 50%;
        z-index: 1;
        position: absolute;
    }
}
