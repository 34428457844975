.assessment-review{
    text-align: right;
    
    button{
        background-color: #212121;
        border: 1px solid #0092FF;
        color: #0092FF;
        border-radius: 8px;
        bottom: 100px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        margin: auto;
        margin-left: 20px;
        padding: 5px 20px;
    }

}