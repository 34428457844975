.questionsDetail{
  .react-tabs {
    display: flex;
    width: 100%;
    height: 100%;
    color: white;
    li:focus {
      outline: none !important;
    }
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 155px;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    color: white;
    background: #2C2C2C;
    overflow-y: auto;
    height: 100%;
  }

  .react-tabs__tab {
    min-height: 35px;
    list-style: none;
    //padding: 10px;  //
    padding: 10px 10px 10px 20px; 
    cursor: pointer;
    border-top: 0.5px solid #656262;
    border-bottom: 0.5px solid #656262;
    overflow-x: scroll;
  }

  .react-tabs__tab--selected {
    background: #4D5875;
    border: none;
  }

  .react-tabs__tab-panel {
    display: none;
    flex-grow: 1;
    background: #212121;
  }

  .react-tabs__tab-panel--selected {
    display: block;
    height: 100%;
  }

  .react-tabs__tab--selected {
    padding-right: 10px;
  }

  .panel-content {
    text-align: center;
  }
}