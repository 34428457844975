.codeEditor{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    
    #ace_code_editor{
        flex-shrink: 0;
        min-height: 70%;
        max-height: 70%;
        min-width: 100%;
        border-radius: 4px;
        border: 1px solid #656262;
    }
}