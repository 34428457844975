.question{
    .question-info{
        .question-wrpr {
            display: flex;
            align-items: center;
            justify-content: center;
            span:first-child {
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;    
                //min-width: 45px;   //
                min-width: 35px;
            }
            span:last-child {
                border-radius: 20px;
                font-weight: 600;
                font-size: 12px;
                line-height: 22px; 
                padding: 0 5px;   
            }
        }
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}