@import "../styles/scss/variables.scss";

.InstructionsPage{
    display: flex;
    flex-direction: row;
    //height: 100%;
    @include calculated-height;

    .instr-wrpr{
        width: 50%;
        padding: 45px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #01579B;
        color: #fff;
        overflow-y: scroll;
        .instr-lbl {
            font-weight: 600;
            font-size: 30px;
            line-height: 41px;
            letter-spacing: 0.02em;
            margin-bottom: 45px;
        }
        ul {
            margin: 0;
            padding: 15px;
            li {
                font-size: 12px;
                //font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 20px;
            }
        }
    }

    .mrking-schm-wrpr{
        width: 50%;
        padding: 45px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow-y: scroll;
        
        .mrking-schm-lbl{
            font-weight: 600;
            font-size: 30px;
            line-height: 41px;
            letter-spacing: -0.02em;
            width: 233px;
            align-self: center;
        }

        .mrking-schm-smry{
            width: 100%;
            align-self: center;
            color: #01579B;
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            margin-top: 30px;
            display: flex;
            justify-content: center;
            .separator {
                border-left: 1px solid #757575;
                margin: 0 40px;
            }
            img {
                margin-right: 25px;
            }
        }

    }

    #upload{
        background-color: #01579B;
        border: #01579B;
        border-radius: 8px;
        padding: 12.5px 35px;
        margin-top: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: 400;
    }
}

