#portal .modal-container {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
}

.modal-container .modal {
    width: 500px;
    min-height: 220px;
    background: #000000;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
    //padding: 10px;
    border: 2px solid #313030;
    color: #FFF;

    .modal-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        padding: 5px 15px;
    }

    .separator{
        border: 1px solid #0092FF;
    }

    .modalContent{
        padding: 5px 15px;
    }

    div{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }

    p{
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
    }
}

.modal button {
    padding: 5px;
    background: transparent;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
}