@import "../styles/scss/variables.scss";

.DefaultLayout {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .container{
        flex-grow: 1;
        margin-top: $header-height;
    }
}
