@import "../styles/scss/variables.scss";

.AppFooter{
    height: $footer-height;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: end;
    padding: 0 20px; //
    flex-shrink: 0;

    &.dark {
        background: #2C2C2C;
        color: #FFFFFF;
    }
}