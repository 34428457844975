.searchControl{
        .search-field{
            display: flex;
            justify-content: center;
            margin-top: 15px;

            .input-group{
                width: 400px;
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
            }

            .input-field{
                display: block;
                width: 100%;
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #212529;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                appearance: none;
                border-radius: 0.375rem;
                padding: 10px;
                position: relative;
                flex: 1 1 auto;
                width: 1%;
                min-width: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            #btn-search{
                padding: 10px;
                color: #6c757d;
                margin-left: -1px;

                position: relative;
                z-index: 2;
                cursor: pointer;
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                border: 1px solid #6c757d;
                border-radius: 0.375rem;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                background-color: transparent;
                font-size: 1rem;
                &:hover {
                    background-color: #6c757d;
                    color: #FFF;
                }
            }
        }

        // .search-field{
        //     display: flex;
        //     justify-content: center;
        //     margin-top: 15px;

        //     .input-group{
        //         width: 400px;
        //     }

        //     .form-control{
        //         padding: 10px;
        //     }

        //     .input-field {
        //         width: 300px;
        //         padding: 10px;
        //         padding-left: 30px;
        //         text-align: left;   
        //         font-size: 1rem;

        //         background: #FFFFFF;
        //         border: 1px solid #f5f5f5;
        //         box-shadow: 0px 1px 10px rgb(0 0 0 / 20%);
        //         border-radius: 40px;
                

        //         background-image: url(../assets/imgs/search.jpg);
        //         background-repeat: no-repeat;
        //         background-position: left 5px center;
        //         outline: 0;
        //     }
        // }

}