@import "./variables.scss";
@import "./tooltip.scss";

* {
  box-sizing: border-box;
}
button {
  cursor: pointer;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Open Sans', 'sans-serif';
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}


