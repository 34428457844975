.submitSuccessPage{
    text-align: center;
    padding: 100px;
}
.thank-you-message{
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #01579B;
}

.submitted-check{
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #212121;
}

.review-information{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #757575;

    a:link, a:visited {
        color: #0000EE;
    }
}

.exam-completed-message{
    background: #858282;
    width: 40%;
    margin: auto;
    padding: 20px;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
}