.questionsPage{
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .questionsDetail{
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        color: #FFF;
    }
}

.no-candidate-profile{
    background: #858282;
    width:400px;
    margin: auto;
    padding: 50px;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
}