$level-height: 20px;

.MarkingSchemeCard {
    min-width: 285px;
    min-height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .block, .text {
        position: absolute;
        border-radius: 4px;
        height: 100%;
        width: 100%;
    }
    .block {
        box-shadow: 0px 1px 6px;
    }
    .text {
        padding: 10px;
        .level {
            position: absolute;
            width: 65px;
            height: $level-height;
            left: -8px;
            top: 10px;
            color: #FFFFFF;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0px 2px 2px 0px;
            .fold {
                position: absolute;
                left: 0;
                top: $level-height;
                width: 0;
                height: 0;
                border-top: 6px solid #4D4D4D;
                border-left: 9px solid transparent;
            }
        }
        .heading {
            font-weight: bold;
            margin-top: 25px;
            font-size: 18px;
            color: #121212;
            line-height: 28px;
        }
        .total {
            font-weight: bolder;
            font-size: 16px;
            line-height: 28px;
        }
        .each {
            margin-left: 5px;
            font-size: 12px;
        }
    }
}