#portal .confirmation-container {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}


.confirmation-container .confirmation-modal {
    width: 450px;
    min-height: 200px;
    background: #000000;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
    border: 2px solid #313030;
    color: #FFF;

    .confirmation-modal-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        padding: 5px 15px;
    }

    .separator{
        border: 1px solid #0092FF;
    }

    .modalContent{
        text-align: center;
        padding: 20px;
    }

    div{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: -0.02em;
    }

    p{
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
    }

    .modal-action{
        text-align: center;
        button{
            background-color: #0092FF;
            border: #0092FF;
            border-radius: 10px;
            padding: 10px;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            line-height: 31px;
            letter-spacing: -0.006em;
            cursor: pointer;
        }
    }
}

.confirmation-modal .close-button {
    padding: 5px;
    background: transparent;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
}
