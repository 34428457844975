.tooltip {
    position: relative;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: #0092FF;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    top: 105%;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}