.exam-admin-info{
    height: 50px;
    font-weight: 600;
    font-size: 20px;
    background: #2C2C2C;
    border-bottom: 1px solid #656262; //
    border-top: 1px solid #656262; //
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    padding: 0 20px; //
    flex-shrink: 0;

    .back-to-list{
        display: flex;
        justify-content: center;
        cursor: pointer;
        font-size: 18px;
        line-height: 25px;

        img{
            margin-right: 5px;
        }
    }

    .user_detail{
        display: flex;
        justify-content: center;
        span{
            font-size: 22px;
            line-height: 25px;
            margin-right: 5px;
        }
        a{
            font-size: 16px;
            line-height: 25px;
            color: #0092FF;
            cursor: pointer;
        }
    }
    .examStartTime{
        display: flex;
        justify-content: center;
        font-size: 18px;
        line-height: 25px;

        img{
            margin-right: 5px;
        }
    }

    .total_marks{
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }
}