.mcqEditor{
    border-radius: 4px;
    border: 1px solid black;
    overflow: hidden;
    border: 1px solid #656262;

    .mcq-question{
        background: #212121;
        width: 100%;
        min-height: 500px;
        padding: 5px;
        .radio-button, .checkbox-button{
            display: flex;
            align-items: center;
            padding: 5px;
            font-size: 16px;
            input{
                height: 18px;
                width: 18px;
                margin-right: 8px;
                flex: none;
            }
        }
    }
 
    .correct-answer{
        background-color: #4d924d;
    }
    .wrong-answer{
        background-color: #ab5656;
    }
}