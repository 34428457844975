.exam-more-info{
    height: 50px;
    font-weight: 600;
    font-size: 20px;
    background: #2C2C2C;
    border-bottom: 1px solid #656262; //
    border-top: 1px solid #656262; //
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    padding: 0 20px; //
    flex-shrink: 0;

    .left-section{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        img{
            height: 20px;
            width: 20px;
            margin-right: 10px;
        }
        cursor: pointer;
    }

    .right-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #btn-submit-test{
        background-color: #01579B;
        border: #01579B;
        border-radius: 8px;
        padding: 5px 25px;
        bottom: 100px;
        color: #FFF;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        margin-left: 25px;
    }
}