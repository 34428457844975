@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-20px);}
	60% {transform: translateY(-15px);}
}

.question-editor{
    display: flex;

    .right-section{
        width: 70%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100vh - 110px - 20px);
        padding: 10px 20px 10px 10px;

        .code-actions{
            margin-bottom: 10px;
            flex-shrink: 0;
            div:first-child{
                z-index: 999;
            }
            .button-panel{
                display: flex;
                align-items: center;
            }

            .button-list{
                display: flex;
                align-items: center;

                img{
                    width: 25px;
                    height: 20px;
                    background-color: #0092FF;
                    margin-left: 10px;
                    margin-top: 5px;
                    border-radius: 4px;
                }
                .bounce {
                    animation: bounce 2s ease infinite;
                }
            }

            .selected-language-text{
                width: 200px;
                height: 40px;
                border-color: #BDBDBD;
                border-radius: 4px;
                border-style: solid;
                border-width: 1px;
                min-height: 38px;
                position: relative;
                color: #FFF;
                background: #2C2C2C;
                padding: 10px;
                font-size: 16px;
                font-weight: 400;
            }
        }

        #btn-run-code, #btn-save-code{
            background-color: #212121;
            border: 1px solid #0092FF;
            color: #0092FF;
            border-radius: 8px;
            bottom: 100px;
            font-size: 20px;
            font-weight: 400;
            cursor: pointer;
            margin: auto;
            margin-left: 20px;
            padding: 5px 20px;

            span{
                display: inline-block;
                padding-left: 5px;
                font-weight: 600;
            }
        }
    }
}