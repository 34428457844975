$black: #212121;
$white: #fff;
$gray: #e0e0e0;
$primaryBlue: #1652f0;
$hoverBlue: #154de0;
$red: #d9605a;


.toast {
    position: fixed;
    top: 70px;
    left: 50%;
    transform: translate(-50%, 0px);
    min-width: 300px;
    max-height: 100px;
    z-index: 9999;

  .toast-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .toast-container-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 15px;
        border-radius: 4px;
        width: 100%;
        min-height: 50px;
        word-wrap: break-word;
        color: $white;
        transition: 0.2s;


        &:not(:first-child) {
            margin-top: -3rem;
        }

        &:hover ~ .toast-container-item,
        &:focus-within ~ .toast-container-item {
            transform: translateY(3rem);
        }

        &.success{
            border: #badbcc;
            color: #0f5132;
            background: #d1e7dd;
        }

        &.info {
            border: #ffecb5;
            color: #664d03;
            background: #fff3cd;
        }

        &.danger {
            border: #f5c2c7;
            color: #842029;
            background: #f8d7da;
        }

        .toast-close {
            cursor: pointer;
            font-size: 20px;
            font-weight: 800;
            text-align: end;
            margin-left: 15px;
        }
        .toast-content{

        }
    }
  }
}

