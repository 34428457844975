.question-desc{
    width: 35%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 15px 15px 15px 20px;
    overflow-y: auto;
    height: calc(100vh - 110px - 20px);
    border-left: 1px solid #656262;
    // border-right: 1px solid #656262;

    .review-later{
        a{
            float: right;
            color: #0092FF;
            margin: 8px;
        }
    }

    .sample-tests, .input-formats{
        margin-bottom: 5px;
        .header{
            font-size: 18px;
            font-weight: 600;
        }
        .sample-test, .input-format{
            margin: 10px;
            p{
                margin: 0;
                padding: 0;
                white-space: pre-wrap;
            }
        }
    }

    .review-later-wrapper{
        text-align: right;
        .review-later {
            color: #0092FF;
            cursor: pointer;
            font-weight: 600;
        }
    }
}